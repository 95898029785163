<template>
  <div class="vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="px-8 pt-8 login-tabs-container">

              <div class="vx-card__title mb-4">
                <h4 class="mb-4">Inicia sesión</h4>
                <p v-if="!onLetters && !isLocked" >Ingresa tu correo o alias para entrar a la Red.</p>
                <p v-if="onLetters && !isLocked">Por favor ahora ingresa tu contraseña.</p>
              </div>

              <vs-alert v-if="onError" class="h-full mb-5" color="danger">
                <span>{{ errorMssg }}</span>
                <span v-if="canRequestNewActivationMail">
                  Puedes solicitar un nuevo correo de activación dando clic
                  <router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="goToRequestActivationEmail">aquí.</router-link>
                </span>
              </vs-alert>

              <h6 v-if="onLetters && !isLocked">¿Son tus iniciales?</h6>
              <h4 class="mb-5 mt-4" v-if="onLetters || isLocked">{{name}}</h4>
              <vx-input-group
                v-if="!onLetters && !isLocked"
                class="w-full mb-4">
                <vs-input
                  :class="emailInputClass"
                  placeholder="Correo o alias"
                  v-validate="'required'"
                  autocomplete="email"
                  data-vv-name="email"
                  autofocus
                  type="username"
                  v-on:keyup.enter="sendEmail"
                  v-model.lazy="email" />

                <template slot="append">
                  <div class="append-text btn-addon" >
                    <vs-button color="dark" @click="toggleEmailInputType()" icon-pack="feather" :icon="emailInputIcon"></vs-button>
                  </div>
                </template>
              </vx-input-group>
              <div v-if="!onLetters && !isLocked" class="flex flex-wrap">
                <vs-switch color="success" v-model="emailRemember" icon-pack="feather" vs-icon="icon-check"/>
                <label class="ml-3">Recordar usuario en este navegador</label>
                <vx-tooltip
                  title="Recordar usuario"
                  color="dark"
                  text="Al activar esta opción solo tendrás que ingresar tu contraseña. Para mantener segura tu cuenta, activa esta opción solo en tus dispositivos personales.">
                  <feather-icon icon="HelpCircleIcon" class="ml-2 cursor-pointer bg-dark text-white rounded-full"></feather-icon>
                </vx-tooltip>
              </div>
              <div class="vx-row mb-2" v-if="onLetters && !isLocked && hasMultipleRoles">
                <div class="vx-col w-full mb-3">
                  <p class="bold">Selecciona la cuenta con la que deseas iniciar sesión:</p>
                </div>
                <div class="vx-col w-full mb-3">
                  <ul class="centerx radio-display">
                    <li class="mt-3" v-for="role in userRolesOptions" :key="role.index">
                      <vs-radio v-model="currentRoleName" :vs-value="role.name">Cuenta de {{role.label}}</vs-radio>
                    </li>
                  </ul>
                </div>
              </div>

              <div v-if="hasMoreThanOneProgram && hasSelectedProgramAdmin">
                <vs-divider></vs-divider>
                <div class="vx-row mb-2">
                  <div class="vx-col w-full mb-3">
                    <p class="bold">Selecciona el programa al que deseas acceder:</p>
                  </div>
                  <div class="vx-col w-full mb-3">
                    <ul class="centerx radio-display">
                      <li class="mt-3" v-for="program in programs" :key="program.id">
                        <vs-radio v-model="currentProgram" :vs-value="program.id">{{program.name}}</vs-radio>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <vx-input-group
                v-if="onLetters && !isLocked"
                class="w-full mb-4">
                <vs-input
                  placeholder="Contraseña"
                  v-validate="'required'"
                  v-on:keyup.enter="login"
                  data-vv-name="password"
                  autocomplete="current-password"
                  :type="passInputType"
                  v-model.lazy="password" />

                <template slot="append">
                  <div class="append-text btn-addon" >
                    <vs-button color="dark" @click="togglePassInputType()" icon-pack="feather" :icon="passInputIcon"></vs-button>
                  </div>
                </template>
              </vx-input-group>

              <vs-button v-if="!onLetters && !isLocked" :disabled="email == null || $isLoading" @click="sendEmail" color="primary" class="mr-5 mt-4">Iniciar sesión</vs-button>
              <vs-button v-if="onLetters && !isLocked" id="login_btn" :disabled="password == null || $isLoading" @click="login" color="primary" class="mr-5 mt-4">Iniciar sesión segura</vs-button>
              <!--vs-button v-if="onLetters" @click="backToEmail" :disabled="$isLoading" type="flat" color="primary" class="mt-4" >No son mis iniciales</vs-button-->

              <div class="mt-base mb-2">
                <div class="text-dark mt-4" v-if="isLocked">
                  <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="gotoPasswordReset">Reestablece tu contraseña.</router-link></span>
                </div>
                <div class="text-dark mt-4" v-if="isLocked">
                  Volver al inicio <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="backToEmail">Da clic aquí.</router-link></span>
                </div>
                <div class="text-dark mt-4" v-if="onLetters">
                  ¿No son tus iniciales? <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="backToEmail">Da clic aquí.</router-link></span>
                </div>
                <div class="text-dark mt-4" v-if="!isLocked">
                  ¿Olvidaste tu contraseña? <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="gotoPasswordReset">Da clic aquí.</router-link></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
import { mapActions, mapMutations } from 'vuex'
const errorMssgDef = "Ha ocurrido un error, intente más tarde."
const textType = "text";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";
import sessionsHelper from "@mixins/sessionsHelper";
export default {
  data() {
    return {
      email: null,
      name: null,
      password: null,
      onLetters: null,
      hasMultipleRoles: false,
      onError: null,
      errorMssg: errorMssgDef,
      showFormPrompt: null,
      emailInputPassMode: true,
      passInputPassMode: true,
      isLocked: false,
      userValidRoles: [],
      canRequestNewActivationMail: false,
      currentRoleName: null,
      userRolesOptions: [],
      programs: null,
      currentProgram: null,
      emailRemember: false
    }
  },
  mixins: [sessionsHelper],
  async mounted() {
      if(localStorage.getItem("emailRemember") !== null) {
          this.email = localStorage.getItem("emailRemember");
          this.emailRemember = true;
          await this.sendEmail();
      }
  },
  computed: {
    emailInputClass(){
      return this.emailInputPassMode ? "input-font-password" : "input-font-regular";
    },
    emailInputIcon(){
      return this.emailInputPassMode ? iconEye : iconEyeOff;
    },
    passInputType(){
      return this.passInputPassMode ? "password" : textType;
    },
    passInputIcon(){
      return this.passInputPassMode ? iconEye : iconEyeOff;
    },
    hasMoreThanOneProgram() {
      if(this.programs != null){
          return this.programs.length > 1;
      } else {
        return false;
      }
    },
    hasSelectedProgramAdmin() {
      return this.currentRoleName == "program_administrator";
    },
    isProgramUser() {
      return (this.currentRoleName == "program_administrator"
              || this.currentRoleName == "program_executive_manager"
              || this.currentRoleName == "program_commercial_manager")
    }
  },
  created() {
    this.ACCOUNT_NOT_ACTIVATED = "account_not_activated";
  },
  methods: {
    ...mapActions('auth', ['getUserInfo']),
    ...mapMutations('auth', ['setStateAccessToken', 'setCurrentProgramData']),
    ...mapMutations('extended_auth', ['set_new_extended_session','set_extended_session_token', 'set_extended_session_role', 'set_extended_session_user']),
    async sendEmail(){
      this.showLoading(true);
      this.onError = false;
      this.errorMssg = null
      try {
        this.hasMultipleRoles = false;
        this.userValidRoles = [];
        this.userRolesOptions = [];
        let response = await this.publicApiPost('/api/v1/auth/get-user-initials', {email_alias: this.email});
        this.loading = false;
        this.name = response.data.initials.join("** ") + "**";
        this.programs = response.data.programs;
        const lockInfo = response.data.lockInfo;
        this.$mixpanel.track('get-initials', {
          email: this.email
        })
        if(lockInfo !== null){
          this.isLocked = (lockInfo.locked === true || lockInfo.locked === "true");
          if(this.isLocked){
            this.onError = true;
            this.errorMssg = lockInfo.message;
            return;
          }
        }

        this.userValidRoles = response.data.validRoles;
        if(this.userValidRoles && this.userValidRoles.length){
          if(this.userValidRoles.length > 1){
            this.setCurrentRolesOptions();
            // seleccionar el rol con mayor jerarquia (index)
            this.currentRoleName = this.userRolesOptions[0].name;
            this.hasMultipleRoles = true;
          }
          else {
            this.currentRoleName = this.userValidRoles[0];
          }
        }
        this.onLetters = true;
        if(this.emailRemember) {
            localStorage.setItem('emailRemember', this.email)
        } else {
            localStorage.removeItem('emailRemember');
        }
      }
      catch (error) {
        this.onError = true;
        this.errorMssg = errorMssgDef;
      }
      this.showLoading(false);
    },
    setCurrentRolesOptions(){
      this.userValidRoles.forEach(f => {
        const rr = this.StaticRolesOptions.find(r => r.name === f);
        this.userRolesOptions.push(rr);
      });
      this.userRolesOptions.sort((a, b) => (a.index < b.index) ? -1 : 1);
    },
    async login(){
      if(this.hasSelectedProgramAdmin && this.hasMoreThanOneProgram){
        if(this.currentProgram == null) {
          this.missingFieldsNotif();
          return;
        }
      }
      this.showLoading(true);
      try {
        const payload = { email: this.email, password: this.password, roleName: this.currentRoleName, emailRemember: false, rememberme: false,  twoFactorCode: "" };
        const res = await this.publicApiPost('api/v1/auth/loginViaApp', payload);
        this.$mixpanel.track('login', {
          email: this.email
        })
        const token = res.data.access_token;
        const loggerRoleName = res.data.logged_role_name;
        // almacenar el token en auth (sessionStorage)
        this.setStateAccessToken(token);

        if(this.hasSelectedProgramAdmin && this.hasMoreThanOneProgram){
          //se guarda el programa seleccionado
          let currentProgram = this.programs.find(x => x.id == this.currentProgram);
          this.setCurrentProgramData(currentProgram);
        } else if(this.isProgramUser) {
          this.setCurrentProgramData(this.programs[0]);
        }

        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        this.$store.state.isUnauthenticated = false;
        const userRes = await this.getUserInfo(loggerRoleName);

        // seteo de metadata para hotjar
        if (this.$hj) {
          var userId = this.UserId || null;
          this.$hj('identify', userId, {
            'user_role': this.AccountMetadata.name,
            'user_role_id': this.AccountMetadata.id
          })
        }

        // verificar si debe almacenarse la sesion extendida
        this.checkForExtendedSession(token, userRes.data);
        this.showFormPrompt = false;

        // redirigir a la vista original a la que el usuario intento entrar
        if(this.$route.query && this.$route.query.redirect){
          const rep = this.$route.query.redirect;
          await this.$router.replace(rep || '/');
        }
        else{
          await this.$router.replace({name: 'home'});
        }
      }
      catch(error) {
        this.onError = true;
        console.log(error)
        if(error && error.response && error.response.data && error.response.data.message){
          this.errorMssg = error.response.data.message;

          if(error.response.data.locked){
            this.isLocked = (error.response.data.locked === "true" || error.response.data.locked === true);
          }
          if(error.response.data.extra && error.response.data.extra === this.ACCOUNT_NOT_ACTIVATED){
            this.canRequestNewActivationMail = true;
          }
        }
        else {
          this.errorMssg = "Ha ocurrido un error. Intenta por favor más tarde.";
        }
        this.warn(error);
      }
      this.showLoading(false);
    },
    checkForExtendedSession(token, user){
      if(this.roleShouldHaveExtendedSession(user.role)){
        // almacenar el token y los datos de la sesion en extended_auth (localStorage)
        this.set_new_extended_session(user.user_id);
        this.set_extended_session_token(token);
        this.set_extended_session_role(user.role);
        this.set_extended_session_user(user);
      }
    },
    async backToEmail(){
      this.errorMssg = null;
      this.isLocked = false;
      this.email = null;
      this.password = null;
      this.onError = false;
      this.onLetters = false;
      this.emailInputPassMode = true;
      this.passInputPassMode = true;
    },
    /**
     * @deprecated
     * @returns {Promise<void>}
     */
    async goToRegisterPath(){
      await this.$router.push({name: 'infoRegistro'});
    },
    async gotoPasswordReset(){
      await this.$router.push({name: 'recuperarPassword'});
    },
    async goToRequestActivationEmail(){
      await this.$router.push({name: 'requestActivationEmail'});
    },
    toggleEmailInputType(){
      this.emailInputPassMode = !this.emailInputPassMode;
    },
    togglePassInputType(){
      this.passInputPassMode = !this.passInputPassMode;
    },
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  /*min-height: 505px;*/

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}

.vx-card.login-card, .full-page-bg-color {
  border-radius: 15px;
}
.vx-card.login-card .d-theme-dark-bg {
  border-radius: 0 15px 15px 0;
}

.input-font-password .vs-input--input.normal{
  font-family: 'Password',monospace;
  /*font-size: 0.8rem !important;
  line-height: 0.7rem;*/
}
</style>
